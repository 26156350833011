// // import styles & images
import '~/styles/index.scss';
import.meta.glob('~/images/', { eager: true })

import { ready } from '~/utils/common';
import Drop from '~/components/Drop';
import HeaderSearch from '~/components/HeaderSearch';

const loadAutocompleteOnFocus = () => {
  const input = document.querySelector('#global-search-input');
  const load = async () => {
    input.removeEventListener('focus', load);

    const { default: init } = await import('~/components/SearchAutocomplete');
    init();
  };

  input.addEventListener('focus', load);
};

ready(() => {
  Drop('#lang-switcher');
  HeaderSearch();
  loadAutocompleteOnFocus();
});

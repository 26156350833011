const ESC_KEY = 27;
const OPENED_MODIFIER = 'drop--opened';

class Drop {
  constructor(selector) {
    this.container = document.querySelector(selector);
    this.trigger = this.container.querySelector('.drop__trigger');
  }

  init() {
    this.trigger.addEventListener('click', this.toggle.bind(this));
  }

  toggle(e) {
    if (e) e.stopPropagation();

    const opened = this.container.classList.toggle(OPENED_MODIFIER);

    if (opened) {
      this.bindClose();
    } else {
      this.unbindClose();
    }
  }

  bindClose() {
    this.bindedClose = this.close.bind(this);
    this.bindedCloseOnEsc = this.closeOnEsc.bind(this);

    document.addEventListener('click', this.bindedClose);
    document.addEventListener('keypress', this.bindedCloseOnEsc);
  }

  unbindClose() {
    document.removeEventListener('click', this.bindedClose);
    document.removeEventListener('keypress', this.bindedCloseOnEsc);

    this.bindedClose = null;
    this.bindedCloseOnEsc = null;
  }

  closeOnEsc(e) {
    if (e.which !== ESC_KEY) return;
    this.close();
  }

  close() {
    this.container.classList.remove(OPENED_MODIFIER);
    this.unbindClose();
  }
}

const init = (selector) => {
  new Drop(selector).init();
};

export default init;

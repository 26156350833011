import { debounce } from "lodash";

var searchInput;

const EMPTY_STATE_CLASS = "global-search__input--not-empty";

const updateStateClass = function() {
  if (searchInput.value && !searchInput.classList.contains(EMPTY_STATE_CLASS)) {
    searchInput.classList.add(EMPTY_STATE_CLASS);
  } else if (!searchInput.value && searchInput.classList.contains(EMPTY_STATE_CLASS)) {
    searchInput.classList.remove(EMPTY_STATE_CLASS);
  }
};

export default function() {
  searchInput = document.getElementById("global-search-input");
  searchInput.addEventListener("blur", debounce(updateStateClass));

  updateStateClass();
}
